import { useMemo, useState } from 'react';
import {
    FormControl,
    InputLabel,
    SelectChangeEvent,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput
} from '@mui/material';
import Select from '@mui/material/Select';
import { IncidentTypeData } from '../../../types/sms';

interface ICompaniesFilter {
    updateCompaniesFilter: React.Dispatch<React.SetStateAction<string[]>>;
    data: IncidentTypeData[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function CompaniesFilter({ data, updateCompaniesFilter }: ICompaniesFilter) {
    const uniqueCompanies = useMemo(() => Array.from(new Set(data.map<string>((d) => d.companyName))), [data]);
    const [companies, setCompanies] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<string | string[]>) => {
        const {
            target: { value }
        } = event;

        // On autofill we get a stringified value.
        const targetCompanies = typeof value === 'string' ? value.split(',') : value;

        setCompanies(targetCompanies);
        updateCompaniesFilter(targetCompanies);
    };

    return (
        <FormControl sx={{ width: '300px', m: 1 }}>
            <InputLabel id="companies-multiple-checkbox-label">Companies</InputLabel>
            <Select
                labelId="companies-multiple-checkbox-label"
                id="companies-multiple-checkbox"
                multiple={true}
                value={companies}
                input={<OutlinedInput label="Companies" />}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                disabled={!uniqueCompanies.length}
            >
                {uniqueCompanies.map((company) => (
                    <MenuItem key={company} value={company}>
                        <Checkbox checked={companies.indexOf(company) > -1} />
                        <ListItemText primary={company} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
