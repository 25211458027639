import { Close, List } from '@mui/icons-material';
import {
    Checkbox,
    Popover,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { TableData } from './types';
import columns from './trackTableColumns';
import { useRef, useState } from 'react';
import { IconButton } from '../buttons';
import { colors, gradients } from '../../theme';

const StyledTableRow = styled(TableRow)`
    &:nth-of-type(even) {
        background-color: #434343;
    }

    &:nth-of-type(odd) {
        background-color: #2f2f2f;
    }
`;

export interface IDataTable<T extends TableData> {
    data?: T[];
    disabled?: boolean;
    isSelected?: (row: T) => boolean;
    numSelected?: number;
    onSelect?: (row: T) => void;
    onSelectAll?: () => void;
    rowCount?: number;
    rowId: keyof T;
}

export default function DataTable<T extends TableData>({
    data = [],
    disabled,
    isSelected,
    numSelected = 0,
    onSelect,
    onSelectAll,
    rowCount = 0,
    rowId
}: IDataTable<T>) {
    const [open, setOpen] = useState(false);
    const anchor = useRef(null);

    return (
        <>
            <IconButton
                disabled={disabled}
                fab
                icon={open ? <Close fontSize="large" /> : <List fontSize="large" />}
                onClick={() => setOpen((v) => !v)}
                ref={anchor}
                variant="outlined"
            />
            <Popover
                anchorEl={anchor.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={() => setOpen(false)}
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.paperBackground,
                        backgroundImage: gradients.paper,
                        marginLeft: 2,
                        maxHeight: 'calc(100% - 16px)',
                        maxWidth: 400,
                        overflow: 'overlay',
                        width: '100%'
                    }
                }}
            >
                <TableContainer
                    // Must be set for sticky header
                    sx={{ maxHeight: 500, backgroundImage: 'inherit' }}
                >
                    <Table stickyHeader sx={{ backgroundImage: 'inherit' }}>
                        <TableHead sx={{ backgroundImage: 'inherit' }}>
                            <TableRow sx={{ backgroundImage: 'inherit' }}>
                                {columns.map((column) => (
                                    <TableCell
                                        align={column.align}
                                        key={column.key as string}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{ backgroundImage: 'inherit' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                {!!onSelectAll && (
                                    <TableCell padding="checkbox" sx={{ backgroundImage: 'inherit' }}>
                                        <Checkbox
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            color="primary"
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            inputProps={{ 'aria-labelledby': 'select all' }}
                                            onChange={onSelectAll}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => {
                                const selected = isSelected?.(row);

                                return (
                                    <StyledTableRow tabIndex={-1} key={row[rowId] as string}>
                                        {columns.map((column) => (
                                            <TableCell align={column.align} key={column.key as string}>
                                                {row[column.key]}
                                            </TableCell>
                                        ))}
                                        {!!onSelect && (
                                            <TableCell padding="checkbox" sx={{ paddingRight: 2 }}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={selected}
                                                    inputProps={{
                                                        'aria-labelledby': row[rowId] as string
                                                    }}
                                                    onChange={() => onSelect?.(row)}
                                                />
                                            </TableCell>
                                        )}
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Popover>
        </>
    );
}
