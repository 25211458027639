import { styled, Typography } from '@mui/material';

export default styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'expanded'
})<{ expanded: boolean }>(({ expanded }) => ({
    display: 'flex',
    justifyContent: expanded ? 'space-between' : 'space-center',
    alignItems: 'center',
    width: '100%'
}));
