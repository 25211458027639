import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../context/Auth';
import { ModuleType } from '../types/api';

interface PropType {
    component: React.FC;
    module: ModuleType;
}

const DisplayRoute: FC<PropType> = (props) => {
    const { config } = useAuthContext();
    let nav = '/playback';
    if (config.module?.playback) {
        nav = '/playback';
    } else if (config.module?.live) {
        nav = '/live';
    } else if (config.module?.staticAnalysis) {
        nav = '/static-analysis';
    } else if (config.module?.dashboard) {
        nav = '/analytics';
    } else if (config.module?.sms) {
        nav = '/sms';
    }

    const isEnable = config.module ? config.module[props.module] : true;

    if (isEnable) return <props.component />;
    return <Navigate to={nav} />;
};

export default DisplayRoute;
