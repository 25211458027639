import { ReactNode, useEffect, useRef } from 'react';
import { useMapContext } from '../../context/Map';
import { MapContainer as StyledMapContainer } from '../../components/styled';

interface IMap {
    children?: ReactNode;
}

export default function Map({ children }: IMap) {
    const map = useMapContext();
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef?.current && map) {
            map.setTarget(containerRef.current);
        }
    }, [map]);

    return (
        <StyledMapContainer>
            <div id="map" ref={containerRef} />
            {children}
        </StyledMapContainer>
    );
}
