import { Feature, getUid, Map, MapBrowserEvent, Overlay } from 'ol';
import { LineString, Point } from 'ol/geom';
import { Circle, Fill, Icon, RegularShape, Stroke, Style } from 'ol/style';
import { Track } from '../types/map';
import { colors } from '../theme';
import { debounce, lighten } from '@mui/material';
import { MutableRefObject, RefObject } from 'react';
import { Config } from '../types/api';

const fillBlue = new Fill({
    color: colors.arriving
});

const blueTriangle = new Style({
    image: new RegularShape({
        fill: fillBlue,
        points: 3,
        radius: 10
    })
});

const unknownSvg = `
  <svg viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg" height="60px" width="120px">
    <style>    
      .big {
        font: bold 16px sans-serif;
      }

      .callsign {
        fill: ${colors.unknown};
        fill-opacity: 1;
        stroke: #a5a5a5;
        stroke-width: 4px;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-opacity: 1;        
      }
    </style>
    
    <line x1="0" y1="60" x2="20" y2="40" stroke="${colors.unknown}" />

    <g transform="translate(20, 0)">
      <rect x="2" y="2" width="100" height="36" class="callsign" />
      <text x="16" y="25" class="big">Unknown</text>
    </g>
  </svg>
`;

function getMarkerColor(track: Track, airport?: string) {
    if (track?.DestinationAirport === airport) {
        return colors.arriving;
    } else if (track?.DepartureAirport === airport) {
        return colors.departing;
    }

    return colors.unknown;
}

export function createSvg(track: Track, airport?: string) {
    if (!(track?.DestinationAirport && track?.DepartureAirport)) {
        let svg = unknownSvg;
        // If callsign or track number present use those
        if (track?.Callsign) {
            svg= unknownSvg.replaceAll('Unknown', track.Callsign);
        } else if (track?.TrackNumber) {
            svg = unknownSvg.replaceAll('Unknown', track.TrackNumber.toString());
        }
        // console.log(unknownSvg);
        return svg.replaceAll('#', '%23');
    }

    const darkBg = getMarkerColor(track, airport);
    const lightBg = lighten(darkBg, 0.2);
    const callsignBg = colors.unknown;

    return `
    <svg viewBox="0 0 170 84" xmlns="http://www.w3.org/2000/svg" height="84px" width="170px">
      <style>
        .small {
          font: bold 12px sans-serif;
        }
    
        .big {
          font: bold 16px sans-serif;
        }
    
        .icon {
          fill: ${darkBg};
          fill-opacity: 1;
        }

        .callsign {
          fill: ${callsignBg};
          fill-opacity: 0.33;
          stroke: ${darkBg};
          stroke-width: 4px;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-opacity: 1;
        }

        .status {
          fill: ${lightBg};
          fill-opacity: 1;
        }
      </style>
      
      <line x1="0" y1="84" x2="20" y2="64" stroke="${colors.unknown}" />

      <g transform="translate(20, 0)">
        <rect x="0" y="0" width="150" height="40" class="icon" />

        <rect x="2" y="2" width="146" height="36" class="callsign" />
        <text x="19" y="25" class="big">
          ${track?.Callsign ?? 'Unknown'}
        </text>

        <rect x="0" y="40" width="75" height="24" class="status" />
        <text x="19" y="56" class="small">
          ${track?.DepartureAirport ?? ''}
        </text>

        <rect x="75" y="40" width="75" height="24" class="status "/>
        <text x="94" y="56" class="small">
        ${track?.DestinationAirport ?? ''}
        </text>
      </g>
    </svg>
 `.replaceAll('#', '%23');
}

export function createPlaybackMarker(track: Track, airport?: string) {
    const feature = new Feature({
        geometry: new Point([...track.WGS84Position].reverse()),
        ...track
    });

    // transformation applied to the image to correctly position it
    const anchor =
        track?.DepartureAirport && track?.DestinationAirport
            ? [0, 84] // airplane feature
            : [0, 60]; // unknown feature

    const svgStyle = new Style({
        image: new Icon({
            anchor,
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            src: 'data:image/svg+xml;utf8,' + createSvg(track, airport)
        })
    });

    feature.setStyle([svgStyle, blueTriangle]);

    return feature;
}

export function createCircleStyles(fill: string, stroke?: string) {
    return new Style({
        image: new Circle({
            radius: 5,
            fill: new Fill({ color: fill }),
            stroke: new Stroke({
                color: stroke ?? fill,
                width: 1
            })
        })
    });
}

export function createCircles(tracks: (Track & { SourceTimestamp: number })[]) {
    const features = tracks.map((track) => {
        return new Feature({
            geometry: new Point([...track.WGS84Position].reverse()),
            ...track
        });
    });
    return features;
}

export function createLinks(features: Feature<Point>[], color: string) {
    if (!features.length) return [];

    // Ensure features are sorted by time
    const sortedFeatures = [...features].sort(
        (a, b) => (a.get("SourceTimestamp") ?? 0) - (b.get("SourceTimestamp") ?? 0)
    );

    const stroke = new Style({ stroke: new Stroke({ color }) });

    return sortedFeatures.reduce((lineAcc, linkedFeature, linkedIndex) => {
        if (linkedIndex > 0) {
            const start = sortedFeatures[linkedIndex - 1]?.getGeometry()?.getCoordinates();
            const end = linkedFeature?.getGeometry()?.getCoordinates();

            // Ensure there is no unintended looping by checking distance
            if (start && end && distanceBetween(start, end) < 500) { // Adjust threshold if needed
                const line = new Feature({
                    geometry: new LineString([start, end])
                });
                line.setStyle([stroke]);
                lineAcc.push(line);
            }
        }
        return lineAcc;
    }, [] as Feature<LineString>[]);
}

/**
 * Helper function to calculate distance between two coordinates.
 * Prevents linking distant points that may cause "looping."
 */
function distanceBetween(coord1: number[], coord2: number[]): number {
    const [lon1, lat1] = coord1;
    const [lon2, lat2] = coord2;
    const R = 6371000; // Earth radius in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
}

export function createLinkedPointTooltipListener(
    map: Map,
    overlay: Overlay,
    contentElem: RefObject<HTMLImageElement>,
    selectedUid: MutableRefObject<string | null>,
    appConfig: Config
) {
    const listener = (event: MapBrowserEvent<PointerEvent>) => {
        if (!map?.hasFeatureAtPixel(event.pixel)) {
            overlay?.setPosition(undefined);
            selectedUid.current = null;
        } else {
            map?.forEachFeatureAtPixel(event.pixel, (feature) => {
                if (feature?.getGeometry() instanceof LineString) {
                    return;
                }

                const uid = getUid(feature);

                if (!selectedUid || selectedUid.current !== uid) {
                    selectedUid.current = uid;

                    const featureCoords = (feature?.getGeometry() as Point)?.getCoordinates();

                    if (featureCoords && contentElem.current) {
                        contentElem.current.src =
                            'data:image/svg+xml;utf8,' + createSvg(feature.getProperties() as Track, appConfig.airport);
                        overlay?.setPosition(featureCoords);
                    }
                }
            });
        }
    };

    return debounce(listener, 20);
}
