import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionSummary, CardContent, CardHeader } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';

interface IReportAccordion extends AccordionProps {
    header: string;
    summary: string;
    initExpanded: boolean;
}

export default function ReportAccordion({ header, summary, initExpanded, children, ...props }: IReportAccordion) {
    const [expanded, setExpanded] = useState(false);

    // Force open/close accordion based on parent state
    useEffect(() => {
        setExpanded(initExpanded);
    }, [initExpanded]);

    const onChange = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={onChange}
            slotProps={{ transition: { timeout: 100 } }}
            {...props}
        >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <CardHeader title={header} subheader={summary} className="py-2 px-4" />
            </AccordionSummary>
            <AccordionDetails>
                <CardContent>{children}</CardContent>
            </AccordionDetails>
        </Accordion>
    );
}
