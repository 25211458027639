import { Alert, AlertColor, Snackbar, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface IToast {
    message?: string | null;
    onClose: () => void;
    severity?: AlertColor;
}

export default function Toast({ message, onClose, severity = 'error' }: IToast) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleClose = useCallback(() => {
        setOpen(false);

        const timeout = setTimeout(onClose, theme.transitions.duration.leavingScreen);

        return () => clearTimeout(timeout);
    }, [onClose, theme.transitions.duration.leavingScreen]);

    useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    return (
        <Snackbar
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            autoHideDuration={5000}
            onClose={handleClose}
            open={open}
        >
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}
