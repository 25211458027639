import { IconButton, styled, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledTypography } from '../styled';

const StyledIconButton = styled(IconButton)`
    border-radius: 0;
    padding: 10px;
    position: relative;
    width: 100%;
    font-size: 18px;
    color: #c8c8c8;

    .icon-label-container {
        display: flex;
        align-items: center;
    }

    svg {
        fill: #fff;
        font-size: 28px;
    }

    .marker {
        background-color: #d24f4f;
        border-radius: 2.5px;
        height: 35px;
        position: absolute;
        right: 2px;
        width: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

interface ISidebarButton {
    icon: JSX.Element;
    label: string;
    to: string;
    expanded: boolean;
}

export default function SidebarNavButton({ icon, label, to, expanded }: ISidebarButton) {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = !!location?.pathname?.startsWith(to);

    return (
        <Tooltip enterDelay={500} placement="right" title={label}>
            <StyledIconButton
                aria-label={label}
                className={classNames({ active: isActive })}
                onClick={() => navigate(to)}
                size="large"
            >
                <StyledTypography expanded={expanded}>
                    <div className="icon-label-container">{expanded && <span>{label}</span>}</div>
                    {icon}
                    {isActive && <span className="marker" />}
                </StyledTypography>
            </StyledIconButton>
        </Tooltip>
    );
}
