import { useState, useEffect, memo } from 'react';
import { NoFaultType } from '../../types/api';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { AMS, BayType, BayExtend } from '../../types/sms';
import moment from 'moment';
import NoFaultMenu from './NoFaultMenu';
import { Collapse, styled, Box, Card, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButtonProps } from '@mui/material/IconButton';

const StyledTablePagination = styled(TablePagination)({
    '& .MuiTablePagination-toolbar': {
        minHeight: '20px'
    },
    '> p': {
        margin: 0
    }
});

export interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})();

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

interface ISMSTable {
    title: string;
    bayType: BayType;
    data: AMS[];
    handleMenuSelected: (id: string, type: NoFaultType, bayType: BayType) => void;
    addedSMSID?: string;
    extendedBay: BayExtend;
    setExtendedBay: React.Dispatch<React.SetStateAction<BayExtend>>;
    bayHeight: string;
}

const SMSTable = ({
    title,
    bayType,
    data,
    handleMenuSelected,
    addedSMSID,
    extendedBay,
    setExtendedBay,
    bayHeight
}: ISMSTable) => {
    useEffect(() => {
        if (addedSMSID) {
            const addedSMSRow = document.getElementById(addedSMSID);
            if (addedSMSRow) {
                addedSMSRow.style.backgroundColor = '#6d7377';
                setTimeout(() => {
                    addedSMSRow.style.backgroundColor = 'unset';
                }, 1000);
            }
        }
    }, [addedSMSID, data]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleExpandClick = () => {
        const extendBayClone = { ...extendedBay };
        extendBayClone[bayType] = !extendBayClone[bayType];
        setExtendedBay(extendBayClone);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const timestampToDate = (timestamp: string): Date => {
        return new Date(moment.utc(timestamp).format('YYYY-MM-DD HH:mm'));
    };

    return (
        <Card>
            <CardHeader
                title={title}
                sx={{ padding: 0, backgroundColor: '#44494d', textAlign: 'center' }}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                    <ExpandMore
                        expand={extendedBay[bayType]}
                        onClick={handleExpandClick}
                        aria-expanded={true}
                        aria-label="show more"
                        sx={{ marginRight: 1 }}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                }
            />
            <Collapse in={extendedBay[bayType]} timeout="auto" unmountOnExit>
                <TableContainer component={Paper} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableBody sx={{ backgroundColor: '#121212' }}>
                            {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                            ).map((row) => (
                                <TableRow key={row.id} id={row.id}>
                                    <TableCell component="th" scope="row" width="15%">
                                        <div>{row.id.substring(0, 15) + '...'}</div>
                                    </TableCell>
                                    <TableCell component="th" scope="row" width="10%">
                                        {timestampToDate(row.timestamp).toLocaleDateString() +
                                            ' ' +
                                            timestampToDate(row.timestamp).toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                    </TableCell>
                                    <TableCell align="left" width="10%">
                                        {row.company}
                                    </TableCell>
                                    <TableCell align="left" width="15%">
                                        {row.type}
                                    </TableCell>
                                    <TableCell align="left" width="30%">
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="right" width="10%">
                                        <NoFaultMenu
                                            rowId={row.id}
                                            severityTitle={row.severity}
                                            bayType={bayType}
                                            handleMenuSelected={handleMenuSelected}
                                        />
                                    </TableCell>
                                    <TableCell align="right" width="10%">
                                        {row.reason}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: '#202021' }}>
                            <TableRow>
                                <StyledTablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    colSpan={7}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page'
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Collapse>
        </Card>
    );
};

export default memo(SMSTable);
