import { styled } from '@mui/material';

export const LoginContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100vh;

    .logo {
        background: linear-gradient(270deg, #812a56 0%, #bf3c51 51.32%, #f27044 100%), #812a56;
        min-height: 272px;
        padding: 52px;
        text-align: center;

        img {
            height: 168px;
            width: 214px;
        }
    }

    .login {
        flex-grow: 1;
        padding: 32px;
        text-align: center;

        form {
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            .MuiTextField-root {
                width: 245px;
            }

            button {
                border-radius: 29px;
                margin-top: 16px;
                padding: 13px 55px;
            }
        }

        h1 {
            font-size: 26px;
            font-weight: 500;
            margin-top: 56px;
        }

        h2 {
            font-size: 22px;
            font-weight: 500;
            margin-top: 68px;
        }
    }

    [class*='MuiCircularProgress-root'] {
        position: absolute;
        height: 24px !important;
        left: 16px;
        width: 24px !important;
    }

    .Mui-error {
        text-align: center;
    }
`;
