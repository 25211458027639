/**
 * Adds alpha to the hex code based on length and state.
 *
 * @param hexCode Hex color code.
 * @param disabled Disabled state.
 * @returns Hex code with alpha.
 */
export function applyHexAlpha(hexCode: string, disabled?: boolean) {
    const alpha1 = disabled ? '3' : 'f';
    const alpha2 = disabled ? '1E' : 'ff';
    const alpha = hexCode.length === 4 ? alpha1 : alpha2;
    return hexCode + alpha;
}

/**
 * Calculates luminance of a color.
 *
 * Modified from source: https://stackoverflow.com/a/9733420
 *
 * @param rgb Color as RGB numerical array.
 * @returns Luminance.
 */
export function getLuminance(rgb: number[]) {
    const a = rgb.map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * Calculates contrast ratio of two colors.
 *
 * Modified from source: https://stackoverflow.com/a/9733420
 * Minimal recommended contrast ratio is 4.5, or 3 for larger font-sizes.
 *
 * @param rgb1 Color as RGB numerical array.
 * @param rgb2 Color as RGB numerical array.
 * @returns Contrast ratio.
 */
export function getContrast(rgb1: number[], rgb2: number[]) {
    const lum1 = getLuminance(rgb1);
    const lum2 = getLuminance(rgb2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

/**
 * Randomly generate a color with a good contrast ratio to black.
 *
 * @returns Color as hex code.
 */
export function getRandomColor(targetBackground: number[] = [0, 0, 0]): string {
    const rgb = [Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), Math.floor(Math.random() * 256)];

    // Calculate contrast with the target background color
    const contrast = getContrast(rgb, targetBackground);

    if (contrast <= 4.5) {
        return getRandomColor();
    }

    return '#' + rgb.map((v) => v.toString(16).padStart(2, '0')).join('');
}

/**
 * Randomly generate a RGB color.
 *
 * @returns Color as hex code.
 */
export function getRGBColor(): string {
    const min1 = Math.ceil(77);
    const max1 = Math.floor(255);
    const min2 = Math.ceil(77);
    const max2 = Math.floor(255);
    const min3 = Math.ceil(77);
    const max3 = Math.floor(255);
    const rgb = [
        Math.floor(Math.random() * (max1 - min1 + 1)) + min1,
        Math.floor(Math.random() * (max2 - min2 + 1)) + min2,
        Math.floor(Math.random() * (max3 - min3 + 1)) + min3
    ];

    return '#' + rgb.map((v) => v.toString(16).padStart(2, '0')).join('');
}
