import { useState } from 'react';
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { TableData } from './types';
import columns from './alarmTableColumns';

const StyledTableRow = styled(TableRow)`
    &:nth-of-type(even) {
        background-color: #434343;
    }

    &:nth-of-type(odd) {
        background-color: #2f2f2f;
    }
`;

export interface IDataTable<T extends TableData> {
    data?: T[];
    rowId: keyof T;
}

export default function DataTable<T extends TableData>({ data = [], rowId }: IDataTable<T>) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <TableContainer
                // Must be set for sticky header
                sx={{ maxHeight: 500, backgroundImage: 'inherit' }}
            >
                <Table stickyHeader sx={{ backgroundImage: 'inherit' }}>
                    <TableHead sx={{ backgroundImage: 'inherit' }}>
                        <TableRow sx={{ backgroundImage: 'inherit' }}>
                            {columns.map((column) => (
                                <TableCell
                                    align={column.align}
                                    key={column.key as string}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ backgroundImage: 'inherit' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <StyledTableRow tabIndex={-1} key={row[rowId] as string}>
                                {columns.map((column) => (
                                    <TableCell align={column.align} key={column.key as string}>
                                        {column.key === 'alarm_start_timestamp'
                                            ? row[column.key]
                                                  .toString()
                                                  .slice(0, row[column.key].toString().lastIndexOf(':'))
                                            : row[column.key]}
                                    </TableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </TableContainer>
        </>
    );
}
