import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { ControlCard, PageContainer } from '../../components/styled';
import { NoFaultType } from '../../types/api';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Grid, Tooltip, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import IconButton from '../../components/buttons/IconButton';

import SMSTable from './SMSTable';

import { getAMS, updateBHS, updateOTP, updateSMS, uploadSMS } from '../../api';
import { AMS, BayType, Type, BayExtend, AMSReturnType, AMSPostType } from '../../types/sms';
import { UploadButton } from '../../components/buttons';
import { Download } from '@mui/icons-material';

const TypographyComponent = ({ ...props }) => <Typography {...props} />;

const StyledTypography = styled(TypographyComponent)({
    marginBottom: '8px',
    border: '1px solid #444d58',
    borderRadius: '5px',
    boxShadow: 'rgba(67, 68, 69) 0px 0px 0px 2px, rgba(67, 68, 69) 0px 4px 6px -1px, rgba(67, 68, 69) 0px 1px 0px inset'
});

export default function SMSPage() {
    const [smsPending, setSmsPending] = useState<AMS[]>([]);
    const [smsEntered, setSmsEntered] = useState<AMS[]>([]);
    const [smsNoFault, setSmsNoFault] = useState<AMS[]>([]);
    const [addedSMSID, setAddedSMSID] = useState<string>();
    const [amsData, setAmsData] = useState<AMSReturnType>();
    const [companyFilterOpen, setCompanyFilterOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [typeFilterOpen, setTypeFilterOpen] = useState(false);
    const typeAnchorRef = useRef<HTMLButtonElement>(null);

    const [typeFilter, setTypeFilter] = useState<Type>();
    const [companies, setCompanies] = useState<string[]>([]);
    const [companyFilter, setCompanyFilter] = useState<string>();

    const handleCompanyFilterToggle = () => {
        setCompanyFilterOpen((prevOpen) => !prevOpen);
    };

    const handleTypeFilterToggle = () => {
        setTypeFilterOpen((prevOpen) => !prevOpen);
    };

    const handleCompanyFilterSelect = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        const target = event.currentTarget as HTMLElement;
        if (target.dataset?.hasOwnProperty('companyFilterValue')) {
            const { companyFilterValue } = target.dataset;
            filterData('company', companyFilterValue);
            setCompanyFilter((companyFilterValue as string) === 'All' ? undefined : companyFilterValue);
        }
        setCompanyFilterOpen(false);
    };

    const filterData = (property: keyof AMS, value?: string) => {
        if (amsData) {
            if (value !== 'All') {
                setSmsPending(amsData.data.pending.filter((d: AMS) => d[property] === value));
                setSmsEntered(amsData.data.entered.filter((d: AMS) => d[property] === value));
                setSmsNoFault(amsData.data.noFault.filter((d: AMS) => d[property] === value));
            } else {
                setSmsPending(amsData.data.pending);
                setSmsEntered(amsData.data.entered);
                setSmsNoFault(amsData.data.noFault);
            }
        }
    };

    const handleTypeFilterSelect = (event: Event | React.SyntheticEvent) => {
        if (typeAnchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        const target = event.currentTarget as HTMLElement;
        if (target.dataset?.hasOwnProperty('typeFilterValue')) {
            const { typeFilterValue } = target.dataset;
            filterData('type', typeFilterValue);
            setTypeFilter(typeFilterValue === 'All' ? undefined : (typeFilterValue as Type));
        }
        setTypeFilterOpen(false);
    };
    const [extendedBay, setExtendedBay] = useState<BayExtend>({ PENDING: true, ENTERED: true, NOFAULT: true });
    const [bayHeight, setBayHeight] = useState('20vh');

    const smsCompare = (firstSMS: AMS, secondSMS: AMS): number => {
        const firstDate = new Date(moment.utc(firstSMS.timestamp).format('YYYY-MM-DD HH:mm'));
        const secondDate = new Date(moment.utc(secondSMS.timestamp).format('YYYY-MM-DD HH:mm'));
        if (firstDate > secondDate) {
            return -1;
        } else if (firstDate < secondDate) {
            return 1;
        }
        return 0;
    };

    const fetchSMSData = async () => {
        const amsData = await getAMS();
        setAmsData(amsData);
        setSmsPending(amsData.data.pending);
        setSmsEntered(amsData.data.entered);
        setSmsNoFault(amsData.data.noFault);
        fetchSMSCompaniesData(amsData.companies);
    };

    const putAmsEntry = async (entry: AMS) => {
        const entryPost: AMSPostType = { uuid: entry.id, severity: entry.severity, reason: entry.reason };

        switch (entry.recordType) {
            case 'SMS':
                await updateSMS(entryPost);
                break;
            case 'BHS':
                await updateBHS(entryPost);
                break;
            case 'OTP':
                await updateOTP(entryPost);
                break;
        }
    };

    const fetchSMSCompaniesData = async (companyList: any) => {
        if (companyList) {
            const SMSCompanies = companyList;
            SMSCompanies.unshift('All');
            setCompanies(SMSCompanies);
        }
    };

    useEffect(() => {
        fetchSMSData().catch(console.error);
    }, []);

    useEffect(() => {
        let extendedBayCount = 0;
        for (let key in extendedBay) {
            if (extendedBay[key as BayType] === true) {
                extendedBayCount++;
            }
        }

        if (extendedBayCount === 3) {
            setBayHeight('20vh');
        } else if (extendedBayCount === 2) {
            setBayHeight('29vh');
        } else if (extendedBayCount === 1) {
            setBayHeight('50vh');
        } else {
            setBayHeight('20vh');
        }
    }, [extendedBay]);

    const handleMenuSelected = (id: string, type: NoFaultType, bayType: BayType) => {
        switch (bayType) {
            case BayType.PENDING: {
                const indexPending = smsPending.findIndex((s) => s.id === id);
                if (indexPending >= 0) {
                    if ([NoFaultType.low, NoFaultType.medium, NoFaultType.high].includes(type)) {
                        smsPending[indexPending].severity = type;
                        putAmsEntry(smsPending[indexPending]);
                        updateSMSArrays(indexPending, smsPending, setSmsPending, smsEntered, setSmsEntered);
                    } else if ([NoFaultType.Mechanical, NoFaultType.TBD].includes(type)) {
                        smsPending[indexPending].severity = 'no fault';
                        smsPending[indexPending].reason = type;
                        putAmsEntry(smsPending[indexPending]);
                        updateSMSArrays(indexPending, smsPending, setSmsPending, smsNoFault, setSmsNoFault);
                    } else {
                        console.log('No menu selected!');
                    }
                } else {
                    console.error('Can not find the incident!');
                }
                break;
            }
            case BayType.ENTERED: {
                const indexEntered = smsEntered.findIndex((s) => s.id === id);
                if (indexEntered >= 0) {
                    if ([NoFaultType.low, NoFaultType.medium, NoFaultType.high].includes(type)) {
                        smsEntered[indexEntered].severity = type;
                        putAmsEntry(smsEntered[indexEntered]);
                        setSmsEntered([...smsEntered]);
                        setAddedSMSID(undefined);
                    } else if ([NoFaultType.Mechanical, NoFaultType.TBD].includes(type)) {
                        smsEntered[indexEntered].severity = 'no fault';
                        smsEntered[indexEntered].reason = type;
                        putAmsEntry(smsEntered[indexEntered]);
                        updateSMSArrays(indexEntered, smsEntered, setSmsEntered, smsNoFault, setSmsNoFault);
                    } else {
                        console.error('Can not find the selected menu!');
                    }
                }
                break;
            }
            case BayType.NOFAULT: {
                const indexNoFault = smsNoFault.findIndex((s) => s.id === id);
                if (indexNoFault >= 0) {
                    if ([NoFaultType.low, NoFaultType.medium, NoFaultType.high].includes(type)) {
                        smsNoFault[indexNoFault].severity = type;
                        smsNoFault[indexNoFault].reason = '';
                        putAmsEntry(smsNoFault[indexNoFault]);
                        updateSMSArrays(indexNoFault, smsNoFault, setSmsNoFault, smsEntered, setSmsEntered);
                    } else {
                        console.error('Can not find the selected menu!');
                    }
                }
                break;
            }
        }
    };

    const updateSMSArrays = (
        foundSMSIndex: number,
        firstArray: AMS[],
        setFirstArray: Dispatch<SetStateAction<AMS[]>>,
        secondArray: AMS[],
        setSecondArray: Dispatch<SetStateAction<AMS[]>>
    ) => {
        setAddedSMSID(firstArray[foundSMSIndex].id);
        secondArray.unshift(firstArray[foundSMSIndex]);
        firstArray.splice(foundSMSIndex, 1);
        setFirstArray([...firstArray].sort(smsCompare));
        setSecondArray([...secondArray].sort(smsCompare));
    };

    const downloadCsv = () => {
        const rows: string[][] = [['ID', 'Date/Time', 'Company', 'Type', 'Descrption', 'Reason', 'Severity']];
        const mapToCSVRows = (e: AMS) => [
            `'${e.id}'`,
            `'${e.timestamp}'`,
            `'${e.company}'`,
            `'${e.type}'`,
            `'${e.description}'`,
            `'${e.reason}'`,
            `'${e.severity}'`
        ];

        rows.push(...smsEntered.map(mapToCSVRows));
        rows.push(...smsPending.map(mapToCSVRows));
        rows.push(...smsNoFault.map(mapToCSVRows));

        const csvContents = 'data:text/csv;charset=utf-8,' + rows.map((r) => r.join()).join('\n');

        const encodedUri = encodeURI(csvContents);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'AMS Data.csv');
        document.body.appendChild(link);
        link.click();
    };

    const getTypeLabel = (type: Type): string => {
        switch (type) {
            case Type.AA:
                return 'Airside';
            case Type.AVOP:
                return 'AVOP';
            case Type.SECURITY:
                return 'Security';
            default:
                return type;
        }
    };

    return (
        <PageContainer sx={{ overflowX: 'hidden' }}>
            <ControlCard>
                <Grid alignItems="flex-start" container justifyContent="space-between" spacing={3} rowSpacing={3}>
                    <Grid item justifyContent="left">
                        <Typography variant="h4" color="textSecondary" padding="2px 10px">
                            AMS Dashboard
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection="row">
                            <Grid item marginRight={2}>
                                <Tooltip title="Upload">
                                    <div>
                                        <UploadButton upload={uploadSMS} />
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Download">
                                    <div>
                                        <IconButton
                                            size="medium"
                                            icon={<Download fontSize="large" />}
                                            variant="outlined"
                                            onClick={downloadCsv}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ControlCard>
            <Typography component="div">
                <TableContainer component={Paper} sx={{ minWidth: 500, overflowX: 'initial' }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" width="15%">
                                    ID
                                </TableCell>
                                <TableCell align="left" width="10%">
                                    DATE/TIME
                                </TableCell>
                                <TableCell align="left" width="10%">
                                    <Typography>
                                        <Button
                                            ref={anchorRef}
                                            id="company-button"
                                            aria-controls={companyFilterOpen ? 'company-menu' : undefined}
                                            aria-expanded={companyFilterOpen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleCompanyFilterToggle}
                                            sx={{ color: '#fff' }}
                                        >
                                            COMPANY
                                            <ArrowDropDownIcon />
                                        </Button>
                                        <Menu
                                            id="company-menu"
                                            anchorEl={anchorRef.current}
                                            open={companyFilterOpen}
                                            onClose={handleCompanyFilterSelect}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button'
                                            }}
                                        >
                                            {companies.map((c) => (
                                                <MenuItem
                                                    key={c}
                                                    data-company-filter-value={c}
                                                    selected={companyFilter ? c === companyFilter : c === 'All'}
                                                    onClick={handleCompanyFilterSelect}
                                                >
                                                    {c}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Typography>
                                </TableCell>
                                <TableCell align="left" width="15%" sx={{ zIndex: 1 }}>
                                    <Typography>
                                        <Button
                                            ref={typeAnchorRef}
                                            id="type-button"
                                            aria-controls={typeFilterOpen ? 'type-menu' : undefined}
                                            aria-expanded={typeFilterOpen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleTypeFilterToggle}
                                            sx={{ color: '#fff' }}
                                        >
                                            TYPE
                                            <ArrowDropDownIcon />
                                        </Button>
                                        <Menu
                                            id="type-menu"
                                            anchorEl={typeAnchorRef.current}
                                            open={typeFilterOpen}
                                            onClose={handleTypeFilterSelect}
                                            MenuListProps={{
                                                'aria-labelledby': 'type-button'
                                            }}
                                        >
                                            <MenuItem
                                                data-type-filter-value={'All'}
                                                onClick={handleTypeFilterSelect}
                                                selected={!typeFilter}
                                            >
                                                All
                                            </MenuItem>
                                            {Object.values(Type).map((t) => (
                                                <MenuItem
                                                    key={t}
                                                    data-type-filter-value={t}
                                                    onClick={handleTypeFilterSelect}
                                                    selected={typeFilter === t}
                                                >
                                                    {getTypeLabel(t)}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Typography>
                                </TableCell>
                                <TableCell align="left" width="30%">
                                    DESCRIPTION
                                </TableCell>
                                <TableCell align="right" width="10%">
                                    SEVERITY
                                </TableCell>
                                <TableCell align="right" width="10%">
                                    REASON
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Typography>
            <StyledTypography component="div">
                <SMSTable
                    title={'PENDING'}
                    data={smsPending}
                    bayType={BayType.PENDING}
                    handleMenuSelected={handleMenuSelected}
                    extendedBay={extendedBay}
                    setExtendedBay={setExtendedBay}
                    bayHeight={bayHeight}
                />
            </StyledTypography>
            <StyledTypography component="div">
                <SMSTable
                    title={'ENTERED'}
                    data={smsEntered}
                    addedSMSID={addedSMSID}
                    bayType={BayType.ENTERED}
                    handleMenuSelected={handleMenuSelected}
                    extendedBay={extendedBay}
                    setExtendedBay={setExtendedBay}
                    bayHeight={bayHeight}
                />
            </StyledTypography>
            <StyledTypography component="div">
                <SMSTable
                    title={'NO FAULT'}
                    data={smsNoFault}
                    addedSMSID={addedSMSID}
                    bayType={BayType.NOFAULT}
                    handleMenuSelected={handleMenuSelected}
                    extendedBay={extendedBay}
                    setExtendedBay={setExtendedBay}
                    bayHeight={bayHeight}
                />
            </StyledTypography>
        </PageContainer>
    );
}
