import { styled } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { useAuthContext } from '../context/Auth';
import { MapProvider } from '../context/Map';

const StyledAuthedRoutes = styled('div')`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;

    main {
        flex-grow: 1;
    }
`;

export default function AuthedRoutes() {
    const { authenticated } = useAuthContext();
    const location = useLocation();

    const shouldRedirect = !authenticated && location?.pathname !== '/login';

    return (
        <MapProvider>
            {shouldRedirect && <Navigate to="/login" replace />}

            {/* Ensures these routes never load when unauthorized. */}
            {!shouldRedirect && (
                <StyledAuthedRoutes>
                    <Sidebar />
                    <main>
                        <Outlet />
                    </main>
                </StyledAuthedRoutes>
            )}
        </MapProvider>
    );
}
