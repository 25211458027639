import { TracksAtTimestamp, TargetsAtSourceTimestamp, Track, TrackRow, TracksByTrackNumber } from '../types/map';
import { filterUniqueAt } from './array';
import { getRandomColor } from './colors';

/**
 * Finds the unique tracks for a given timestamp threshold.
 *
 * @param tracks Tracks to filter.
 * @param timestamp Timestamp to match, based on threshold.
 * @param threshold Threshold to divide the raw timestamps
 * @returns Unique tracks.
 */
function getLastUniqueTracks(tracks: TargetsAtSourceTimestamp[], timestamp: number, threshold: number) {

    // Try and filter by Track Number if track number is available.
    // Otherwise, use Callsign.
    if (tracks.some((track) => track.Targets.some((target) => target.TrackNumber))) {
        return tracks
            .filter((track) => Math.trunc(track.SourceTimestamp / threshold) === timestamp)
            .reduce((acc, track) => {
                acc.push(...track.Targets);
                return acc;
            }, [] as Track[])
            .reverse()
            .filter(filterUniqueAt('TrackNumber'));
    } else if (tracks.some((track) => track.Targets.some((target) => target.Callsign))) {
        return tracks
            .filter((track) => Math.trunc(track.SourceTimestamp / threshold) === timestamp)
            .reduce((acc, track) => {
                acc.push(...track.Targets);
                return acc;
            }, [] as Track[])
            .reverse()
            .filter(filterUniqueAt('Callsign'));
    }

    // else return all tracks.
    return tracks
        .filter((track) => Math.trunc(track.SourceTimestamp / threshold) === timestamp)
        .reduce((acc, track) => {
            acc.push(...track.Targets);
            return acc;
        }, [] as Track[])
        .reverse();
}

/**
 * Finds the tracks from the previous interval that are missing in the current interval.
 *
 * @param tracks Unique tracks from current interval.
 * @param prevTracks Unique tracks from previous interval.
 * @returns Tracks from previous interval missing in current.
 */
function getTracksToPersist(tracks: Track[], prevTracks: Track[]) {
    // if track number is available, use it to filter.
    // else use callsign
    if (tracks.some((track) => track.TrackNumber)) {
        return prevTracks.filter(
            (prevTarget) => !tracks.some((currentTarget) => currentTarget.TrackNumber === prevTarget.TrackNumber)
        );
    } else if (tracks.some((track) => track.Callsign)) {
        return prevTracks.filter(
            (prevTarget) => !tracks.some((currentTarget) => currentTarget.Callsign === prevTarget.Callsign)
        );
    }
    return [];
}

/**
 * Creates an array of unique tracks per timestamp interval based on a millisecond threshold.
 *
 * @param tracks Raw track data.
 * @param startTime Start time.
 * @param endTime End time.
 * @param threshold Millisecond interval per playback frame.
 * @returns Unique tracks per timestamp
 */
export function transformPlaybackTracks(
    tracks: TargetsAtSourceTimestamp[],
    startTime?: Date | null,
    endTime?: Date | null,
    threshold: number = 5000
): TracksAtTimestamp[] {
    if (!tracks?.length || !startTime || !endTime) {
        return [];
    }

    const start = Math.trunc(startTime.valueOf() / threshold);
    const end = Math.trunc(endTime.valueOf() / threshold);
    const intervals = end - start + 1; // Fix off-by-one error

    const tracksByInterval = [] as TracksAtTimestamp[];

    let lastKnownTracks: Track[] = []; // Track the last known targets

    for (let i = 0; i < intervals; i++) {
        const timestampInterval = start + i;
        let tracksAtInterval = getLastUniqueTracks(tracks, timestampInterval, threshold);

        if (tracksAtInterval.length === 0) {
            // Persist last known tracks if no new data exists
            tracksAtInterval = [...lastKnownTracks];
        } else {
            // Persist relevant tracks from previous frame
            const tracksToPersist = getTracksToPersist(tracksAtInterval, lastKnownTracks);
            tracksAtInterval.push(...tracksToPersist);
        }

        // Update last known tracks
        lastKnownTracks = tracksAtInterval;

        tracksByInterval.push({
            timestamp: timestampInterval * threshold, // Restore milliseconds
            tracks: tracksAtInterval
        });
    }

    return tracksByInterval;
}

export function transformStaticAnalysisTracks(tracksBySourceTimestamp: TargetsAtSourceTimestamp[]) {
    return tracksBySourceTimestamp.reduce((acc, { SourceTimestamp, Targets }) => {
        Targets.forEach((track) => {
            const trackWithTimestamp = { ...track, SourceTimestamp };
            let trackKey = track.TrackNumber ?? callsignToNumber(track.Callsign);

            if (acc?.[trackKey]) {
                acc[trackKey].tracks.push(trackWithTimestamp);
            } else {
                acc[trackKey] = {
                    color: getRandomColor(),
                    tracks: [trackWithTimestamp]
                };
            }
        });
        return acc;
    }, {} as TracksByTrackNumber);
}

/**
 * Converts a Callsign to a unique, consistent number using a hash function.
 */
function callsignToNumber(callsign: string): number {
    let hash = 0;
    for (let i = 0; i < callsign.length; i++) {
        hash = (hash << 5) - hash + callsign.charCodeAt(i);
        hash |= 0; // Convert to 32-bit integer
    }
    return Math.abs(hash); // Ensure it's positive
}

function sortTrackRows(tracks: TrackRow[]) {
    return tracks.sort((a, b) =>
        // compare airplanes by callsign
        a?.Callsign && b?.Callsign && a.Callsign !== b.Callsign
            ? a.Callsign.localeCompare(b.Callsign)
            : // unknown < airplane
              !a?.Callsign && !!b?.Callsign
              ? 1
              : // airplane > unknown
                !!a?.Callsign && !b?.Callsign
                ? -1
                : // compare unknowns by track number
                  a.TrackNumber - b.TrackNumber
    );
}

export function transformTableRowTracksByTimestamp(tracksByTimestamp: TracksAtTimestamp[]) {
    const tracks = tracksByTimestamp.reduce((acc, tracksAtTimestamp) => {
        tracksAtTimestamp.tracks.forEach((track) => {
            const exists = acc.find((accTrack) => accTrack.TrackNumber === track.TrackNumber);

            if (!exists) {
                acc.push({
                    AircraftType: track?.AircraftType,
                    Callsign: track?.Callsign,
                    TrackNumber: track.TrackNumber
                });
            }
        });

        return acc;
    }, [] as TrackRow[]);

    return sortTrackRows(tracks);
}

export function transformTableRowTracksByNumber(tracksByNumber: TracksByTrackNumber): TrackRow[] {
    const tracks = Object.values(tracksByNumber).map(({ tracks }) => ({
        AircraftType: tracks[0]?.AircraftType,
        Callsign: tracks[0]?.Callsign,
        TrackNumber: tracks[0]?.TrackNumber
    }));

    return sortTrackRows(tracks);
}
