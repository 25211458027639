import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../context/Auth';
import { ModuleType } from '../types/api';

import {
    AuthedRoutes,
    StaticAnalysisPage,
    LoginPage,
    NotFoundPage,
    PlaybackPage,
    DisplayRoute,
    SMSPage,
    LivePage,
    AnalyticsPage
} from './';

export default function App() {
    const Playback = ModuleType.playback;
    const Live = ModuleType.live;
    const StaticAnalysis = ModuleType.staticAnalysis;
    const Dashboard = ModuleType.dashboard;
    const SMS = ModuleType.sms;

    return (
        <AuthProvider>
            {/* Routes nest inside one another. Nested route paths build upon
          parent route paths, and nested route elements render inside
          parent route elements. See the note about <Outlet> below. */}
            <Routes>
                <Route path="login" element={<LoginPage />} />

                <Route path="/" element={<AuthedRoutes />}>
                    <Route path="analytics" element={<DisplayRoute component={AnalyticsPage} module={Dashboard} />} />
                    <Route path="playback" element={<DisplayRoute component={PlaybackPage} module={Playback} />} />
                    <Route path="live" element={<DisplayRoute component={LivePage} module={Live} />} />
                    <Route
                        path="static-analysis"
                        element={<DisplayRoute component={StaticAnalysisPage} module={StaticAnalysis} />}
                    />
                    <Route path="ams" element={<DisplayRoute component={SMSPage} module={SMS} />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>

                {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </AuthProvider>
    );
}
