import { styled } from '@mui/material';

export default styled('div')`
    height: 100%;
    position: relative;
    width: 100%;

    #map {
        flex-grow: 1;
        height: 100%;
        width: 100%;
    }

    .ol-rotate {
        opacity: 75%;

        &:hover {
            opacity: 100%;
        }

        &.ol-hidden {
            -webkit-animation: fadeOpacity 0.5s ease-out;
            -moz-animation: fadeOpacity 0.5s ease-out;
            -o-animation: fadeOpacity 0.5s ease-out;
            animation: fadeOpacity 0.5s ease-out;

            opacity: 40%;
        }

        .ol-rotate-reset {
            background-color: transparent;
            border: none;
            position: absolute;
            bottom: 8px;
            right: 8px;
            outline: none;
        }

        .north-arrow {
            display: flex;
            height: 80px;
        }
    }

    @-webkit-keyframes fadeOpacity {
        0% {
            display: block;
            opacity: 85%;
        }

        100% {
            display: block;
            opacity: 40%;
        }
    }

    @-moz-keyframes fadeOpacity {
        0% {
            display: block;
            opacity: 85%;
        }

        100% {
            display: block;
            opacity: 40%;
        }
    }

    @-o-keyframes fadeOpacity {
        0% {
            display: block;
            opacity: 85%;
        }

        100% {
            display: block;
            opacity: 40%;
        }
    }

    @keyframes fadeOpacity {
        0% {
            display: block;
            opacity: 85%;
        }

        100% {
            display: block;
            opacity: 40%;
        }
    }
`;
