import { Pause, PlayArrow, Stop } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { IconButton } from '../../components/buttons';
import { PlaybackMode } from './enums';

interface IPlaybackControls {
    disabled?: boolean;
    pause: () => void;
    play: () => void;
    playbackMode: PlaybackMode;
    playDisabled?: boolean;
    stop: () => void;
}

export default function PlaybackControls({
    disabled,
    pause,
    play,
    playbackMode,
    playDisabled,
    stop
}: IPlaybackControls) {
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <IconButton
                    disabled={disabled || playbackMode === PlaybackMode.Play || playDisabled}
                    icon={<PlayArrow fontSize="large" />}
                    onClick={play}
                    size="large"
                />
            </Grid>
            <Grid item>
                <IconButton
                    disabled={disabled || playbackMode === PlaybackMode.Pause || playbackMode === PlaybackMode.Stop}
                    icon={<Pause fontSize="large" />}
                    onClick={pause}
                    size="large"
                />
            </Grid>
            <Grid item>
                <IconButton
                    disabled={disabled || playbackMode === PlaybackMode.Stop}
                    icon={<Stop fontSize="large" />}
                    onClick={stop}
                    size="large"
                />
            </Grid>
        </Grid>
    );
}
