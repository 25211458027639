import { CircularProgress, darken, lighten, styled } from '@mui/material';
import MUIIconButton, { IconButtonClasses, IconButtonProps } from '@mui/material/IconButton';
import classNames from 'classnames';
import { ForwardedRef, forwardRef, MouseEvent } from 'react';
import { applyHexAlpha } from '../../utils/colors';
import { ButtonColors, ButtonSizes, ButtonTypes, IconButtonVariants } from './types';

const StyledMUIIconButton = styled(MUIIconButton)<
    {
        color?: ButtonColors;
        fab?: boolean;
        variant?: IconButtonVariants;
    } & IconButtonProps
>(({ disabled, color, fab, theme, variant }) => {
    const styleAcc = [];

    if (variant === 'outlined') {
        const hexCode = color ? theme.palette[color].main : theme.palette.text.primary;
        styleAcc.push(`border: 4px solid ${applyHexAlpha(hexCode, disabled)};`);
        styleAcc.push(`
      &.inactive:not(:disabled) {
        color: ${darken(hexCode, 0.5)};
        border-color: ${darken(hexCode, 0.5)};
      }
    `);
    }

    return `
    ${styleAcc.join('\n')}

    &.fab {
      background-color: ${theme.palette.background.default};
      &:hover {
        background-color: ${lighten('#000', 0.15)};
      }
    }

    [class*="MuiCircularProgress-root"] {
      position: absolute;
      height: 49px !important;
      width: 49px !important;
    }
  `;
});

interface IIconButton extends IconButtonProps {
    classes?: Partial<IconButtonClasses>;
    className?: string;
    color?: ButtonColors;
    disabled?: boolean;
    fab?: boolean;
    icon: JSX.Element;
    label?: string;
    loading?: boolean;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
    size?: ButtonSizes;
    type?: ButtonTypes;
    variant?: IconButtonVariants;
}

function IconButton(
    {
        classes,
        className,
        color,
        disabled,
        fab,
        icon,
        label,
        loading,
        onClick,
        size,
        type = 'button',
        variant,
        component
    }: IIconButton,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const rootClassname = classNames(className, { fab });
    return (
        <StyledMUIIconButton
            aria-label={label}
            classes={classes}
            className={rootClassname}
            color={color}
            disabled={disabled || loading}
            onClick={onClick}
            ref={ref}
            size={size}
            sx={{ boxShadow: fab ? 6 : undefined, zIndex: 9999 }}
            type={type}
            variant={variant}
            component={component}
        >
            {icon}
            {loading && <CircularProgress />}
        </StyledMUIIconButton>
    );
}

export default forwardRef(IconButton);
