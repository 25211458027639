import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <div>
            <h2>No match found!</h2>
            <p>
                <Link to="/playback">Return to the app</Link>
            </p>
        </div>
    );
}
