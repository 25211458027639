import { Map as OlMap } from 'ol';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getMap } from '../api';
import { MapConfig } from '../types/map';
import { createMap } from '../utils/map';
import { useAuthContext } from './Auth';

export const mapContext = createContext<OlMap | null>(null);

export default function useMap() {
    const { authenticated } = useAuthContext();
    const [config, setConfig] = useState<MapConfig | null>(null);
    const [map, setMap] = useState<OlMap | null>(null);

    useEffect(() => {
        if (authenticated) {
            try {
                getMap().then((_config) => {
                    setConfig(_config);
                });
            } catch (error) {
                setConfig(null);
                setMap(null);
            }
        } else {
            setConfig(null);
            setMap(null);
        }
    }, [authenticated]);

    useEffect(() => {
        if (config) {
            const olMap = createMap(config);
            setMap(olMap);

            return () => {
                olMap.dispose();
            };
        }
    }, [config]);

    return map;
}

interface IMapProvider {
    children?: ReactNode;
}

export function MapProvider({ children }: IMapProvider) {
    const map = useMap();
    return <mapContext.Provider value={map}>{children}</mapContext.Provider>;
}

export const useMapContext = () => useContext(mapContext);
