import { FilterOption, TimeFormat } from '../types/chart';

/**
 * Return Time Format Options object base on the date interval.
 *
 * @param start set the start date
 * @param end set the end date
 * @returns return inital Bar chart option object.
 */
export function getTimeFormatOptions(start: Date, end: Date): FilterOption[] {
    const options: FilterOption[] = [];

    if (end > start) {
        const endTime = end.getTime();
        const startTime = start.getTime();

        const diffYears = end.getFullYear() - start.getFullYear();
        const diffHours = (endTime - startTime) / (1000 * 3600);
        const diffDays = diffHours / 24;

        if (diffYears >= 2) {
            options.push({ id: TimeFormat.YEARLY, value: 'Yearly' });
        }
        if (diffDays >= 62 && diffYears < 2) {
            options.push({ id: TimeFormat.MONTHLY, value: 'Monthly' });
            options.push({ id: TimeFormat.BIMONTHLY, value: 'BiMonthly' });
        }
        if (diffHours >= 48 && diffDays < 62) {
            options.push({ id: TimeFormat.DAILY, value: 'Daily' });
        }
        if (diffHours < 48) {
            options.push({ id: TimeFormat.DAILY, value: 'Hourly' });
        }
    } else {
        console.error('End date must bigger than Start date!');
    }
    return options;
}
