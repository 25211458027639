import { useCallback, useEffect, useMemo, useState } from 'react';
import { TrackRow } from '../types/map';

/**
 * Standard selection handlers/effects for working with the track selection table.
 *
 * @param trackList List of tracks displayed in the table.
 * @returns Table handlers and current selections.
 */
export default function useTableSelect(trackList: TrackRow[]) {
    const [selectedTracks, setSelectedTracks] = useState<number[]>(trackList.map((r) => r.TrackNumber));

    const onSelect = useCallback((row: TrackRow) => {
        setSelectedTracks((selected) => {
            const index = selected.findIndex((trackNum) => trackNum === row.TrackNumber);

            if (index < 0) {
                return selected.concat(row.TrackNumber);
            } else {
                return selected.slice(0, index).concat(selected.slice(index + 1));
            }
        });
    }, []);

    const onSelectAll = useCallback(() => {
        setSelectedTracks((selected) => {
            if (selected.length === trackList.length) {
                return [];
            } else {
                return trackList.map((track) => track.TrackNumber);
            }
        });
    }, [trackList]);

    const isSelected = useCallback(
        (row: TrackRow) => {
            return !!selectedTracks.find((trackNum) => trackNum === row.TrackNumber);
        },
        [selectedTracks]
    );

    const clearSelected = useCallback(() => setSelectedTracks([]), []);

    // reset selected list on track list change
    useEffect(() => {
        setSelectedTracks(trackList.map((r) => r.TrackNumber));
    }, [trackList]);

    // clear invalid table selections
    useEffect(() => {
        if (selectedTracks?.length) {
            const invalidSelections = selectedTracks.filter(
                (trackNumber) => !trackList.some((track) => track.TrackNumber === trackNumber)
            );

            if (invalidSelections.length) {
                setSelectedTracks((tracks) => tracks.filter((trackNumber) => !invalidSelections.includes(trackNumber)));
            }
        }
    }, [selectedTracks, trackList]);

    return useMemo(
        () => ({
            clearSelected,
            isSelected,
            onSelect,
            onSelectAll,
            selectedTracks
        }),
        [clearSelected, isSelected, onSelect, onSelectAll, selectedTracks]
    );
}
