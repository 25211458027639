import { useCallback, useEffect, useState } from 'react';
import { ApiError } from '../types/api';
import { TargetsAtSourceTimestamp } from '../types/map';
import { useAuthContext } from '../context/Auth';

interface LiveTracksData {
    clearErrorMsg: () => void;
    errorMsg: string | null;
    tracks: TargetsAtSourceTimestamp[] | null;
}

const TRACK_TIMEOUT = 10000; // 10 seconds timeout

export default function useLiveTracks(): LiveTracksData {
    const { socket } = useAuthContext();
    const [tracks, setTracks] = useState<TargetsAtSourceTimestamp[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    function onDataFetched(newTrack: TargetsAtSourceTimestamp) {    
        const currentTime = Date.now();    
        setTracks((previous) => {
            // Create a new map of all existing tracks by TrackNumber or Callsign
            const trackMap = new Map<string, TargetsAtSourceTimestamp>();
    
            // Add previous tracks to the map
            previous.forEach(existingTrack => {
                existingTrack.Targets.forEach(target => {
                    const uniqueKey = target.TrackNumber?.toString() ?? target.Callsign;
                    if (uniqueKey) {
                        trackMap.set(uniqueKey, existingTrack);
                    }
                });
            });
    
            // Process new incoming targets
            newTrack.Targets.forEach(newTarget => {
                const uniqueKey = newTarget.TrackNumber?.toString() ?? newTarget.Callsign;
                if (!uniqueKey) {
                    // console.log("Skipping target without Callsign or TrackNumber");
                    return;
                }
    
                if (trackMap.has(uniqueKey)) {
                    // Update existing track
                    const existingTrack = trackMap.get(uniqueKey)!;
                    existingTrack.Targets = existingTrack.Targets.map(target =>
                        (target.TrackNumber === newTarget.TrackNumber || target.Callsign === newTarget.Callsign)
                            ? { ...newTarget, lastUpdated: currentTime }
                            : target
                    );
                    trackMap.set(uniqueKey, existingTrack);
                } else {
                    // Create a new track
                    trackMap.set(uniqueKey, {
                        ...newTrack,
                        Targets: [{ ...newTarget, lastUpdated: currentTime }]
                    });
                }
            });
    
            // Return updated track list
            return Array.from(trackMap.values());
        });
    }
    

    // Timeout mechanism to remove stale targets
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = Date.now();
            
            setTracks(prevTracks => prevTracks.map(track => ({
                ...track,
                Targets: track.Targets.filter(target => (currentTime - (target.lastUpdated || 0)) < TRACK_TIMEOUT)
            })).filter(track => track.Targets.length > 0)); // Remove empty tracks
        }, 1000); // Check every second

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        function onError(errMsg: string) {
            return setErrorMsg(errMsg);
        }

        socket.on('live:data', onDataFetched);
        socket.on('live:error', onError);

        return () => {
            socket.off('live:data', onDataFetched);
            socket.off('live:error', onError);
        };
    }, []);

    const clearErrorMsg = useCallback(() => setErrorMsg(null), []);

    return {
        clearErrorMsg,
        errorMsg,
        tracks
    };
}
