import { TargetsAtSourceTimestamp, Track } from '../types/map';
import { stringSort } from './array';

import { FilterOption, TimeFormat } from '../types/chart';

/**
 * Pulls the airline code from a track's callsign, if available.
 *
 * @param track Single instance of a track's data.
 * @returns Airline code or undefined.
 */
function getAirlineCode(track: Track) {
    return track?.Callsign?.match(/[a-z]+/i)?.[0];
}

/**
 * Creates select filter options based on the tracks data.
 *
 * @param tracks Array of track targets at a timestamp (raw data from the Searidge API).
 * @param key Attribute key to search for values.
 * @returns Options array formatted for the Select component.
 */
export function createOptionsByKey(
    tracks: TargetsAtSourceTimestamp[] | null,
    key: 'AircraftType' | 'Callsign' | 'Airline'
) {
    const values = tracks
        ?.map((track) => {
            return track.Targets.reduce((acc, target) => {
                if (key === 'Airline') {
                    const airline = getAirlineCode(target);

                    if (airline) {
                        acc.push(airline);
                    }
                } else {
                    const value = target?.[key];

                    if (value) {
                        acc.push(value);
                    }
                }

                return acc;
            }, [] as string[]);
        })
        .flat();

    return [...new Set(values)].sort(stringSort).map((value) => ({ id: value, value: value }));
}

/**
 * Filters
 *
 * @param targets Target attribute (list of tracks) from an entry in the API data.
 * @param key Attribute being filtered.
 * @param filter Values to filter for.
 * @returns Tracks that pass the filter.
 */
function filterByKey(targets: Track[], key: 'AircraftType' | 'Callsign' | 'Airline', filter: string[]) {
    return targets.filter((target) => {
        if (key === 'Airline') {
            const airline = getAirlineCode(target);
            return airline && filter.some((value) => value === airline);
        } else {
            return target?.[key] && filter.some((value) => value === target[key]);
        }
    });
}

/**
 * Filter tracks (raw API data) based on specified filters.
 *
 * @param tracks Tracks to filter.
 * @param filters Filter keys and values to filter by.
 * @returns Tracks that pass the filter.
 */
export function filterTracks(
    tracks: TargetsAtSourceTimestamp[] | null,
    filters: Partial<Record<'AircraftType' | 'Callsign' | 'Airline', string[]>>
) {
    const filteredTracks =
        tracks?.reduce(
            (acc, track, index) => {
                acc[index] = { ...track, Targets: [...track.Targets] };

                Object.entries(filters).forEach(([key, value]) => {
                    if (value?.length) {
                        acc[index].Targets = filterByKey(acc[index].Targets, key as keyof typeof filters, value);
                    }
                });

                return acc;
            },
            [] as typeof tracks
        ) ?? [];

    return filteredTracks;
}

/**
 * Filter tracks (raw API data) based on specified filters.
 *
 * @param list Chart filter options list.
 * @returns Options that pass to chart filter.
 */
export function filterOptions(list: TimeFormat[]) {
    const options: FilterOption[] = [];
    for (const i in list) {
        options.push({
            id: list[i],
            value: list[i]
        });
    }
    return options;
}
