import { FormControl, InputLabel, MenuItem } from '@mui/material';
import MUISelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { Control, Controller } from 'react-hook-form';
import { BaseField } from './types';

interface IOption {
    id: string;
    value: string;
}
interface ITimeForamt extends Omit<BaseField, 'control'> {
    multiple?: boolean;
    control?: Control<any>;
    error?: string;
    options: IOption[];
}

export default function TimeFormatSelect({
    control,
    defaultValue,
    disabled,
    error,
    fullWidth,
    id,
    label,
    multiple,
    options,
    rules
}: ITimeForamt) {
    /**
     * Blurs the component when closing the menu. Does not function without the brief delay from setTimeout.
     */
    function handleClose() {
        setTimeout(() => (document?.activeElement as HTMLDivElement)?.blur(), 0);
    }
    return (
        <Controller
            control={control}
            defaultValue={multiple && !Array.isArray(defaultValue) ? [defaultValue] : defaultValue}
            name={id}
            render={({ field }) => (
                <FormControl disabled={disabled} fullWidth={fullWidth}>
                    {label && <InputLabel>{label}</InputLabel>}
                    <MUISelect {...field} label={label} multiple={multiple} onClose={handleClose} error={!!error}>
                        {!!options?.length &&
                            options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                    </MUISelect>
                    <FormHelperText error={true}>{error}</FormHelperText>
                </FormControl>
            )}
            rules={rules}
        />
    );
}
