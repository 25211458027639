import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Select } from '../../components/form';
import { TargetsAtSourceTimestamp } from '../../types/map';
import { createOptionsByKey, filterTracks } from '../../utils/filters';
import { FilterForm } from './types';

interface IPlaybackFilters {
    control: Control<FilterForm>;
    disabled?: boolean;
    tracks: TargetsAtSourceTimestamp[] | null;
}

export default function PlaybackFilters({ control, disabled, tracks }: IPlaybackFilters) {
    const airline = useWatch({ control, name: 'airline' });
    const callsign = useWatch({ control, name: 'callsign' });

    const airlineOptions = useMemo(() => {
        return createOptionsByKey(tracks, 'Airline');
    }, [tracks]);

    const callsignOptions = useMemo(() => {
        const filteredTracks = filterTracks(tracks, { Airline: airline });
        return createOptionsByKey(filteredTracks, 'Callsign');
    }, [airline, tracks]);

    const aircraftTypeOptions = useMemo(() => {
        const filteredTracks = filterTracks(tracks, {
            Airline: airline,
            Callsign: callsign
        });
        return createOptionsByKey(filteredTracks, 'AircraftType');
    }, [airline, callsign, tracks]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Select
                    control={control}
                    disabled={disabled}
                    fullWidth
                    id="airline"
                    label="Airline"
                    multiple
                    options={airlineOptions}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    control={control}
                    disabled={disabled}
                    fullWidth
                    id="callsign"
                    label="Callsign"
                    multiple
                    options={callsignOptions}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    control={control}
                    disabled={disabled}
                    fullWidth
                    id="aircraftType"
                    label="Aircraft Type"
                    multiple
                    options={aircraftTypeOptions}
                />
            </Grid>
        </Grid>
    );
}
