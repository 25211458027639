import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTimeRangePicker } from '../../components/form';
import { DateTimeRangeForm } from '../../components/form/types';
import { Map, PlaybackMarkers } from '../../components/map';
import { ControlCard, PageContainer } from '../../components/styled';
import { Table } from '../../components/table';
import Toast from '../../components/Toast';
import { FloatingLeftGroup, FloatingBottomRightGroup } from '../../components/styled/FloatingGroups';
import { useTableSelect, useTracks } from '../../hooks';
import { filterTracks } from '../../utils/filters';
import { transformPlaybackTracks } from '../../utils/tracks';
import { PlaybackMode } from './enums';
import PlaybackControls from './PlaybackControls';
import PlaybackFilters from './PlaybackFilters';
import { FilterForm } from './types';
import { useWorkerMemo, createWorkerFactory } from 'use-worker-promise';
import { useAuthContext } from '../../context/Auth';
import { PLAYBACK_PAGE_MAX_TIME_RANGE_DEFAULT_INTERVAL } from '../../utils/defaultValues';

const transformTableRowTracksByTimestamp = createWorkerFactory<
    import('../../utils/workers/tracks/transformTableRowTracksByTimestamp').Worker
>(
    () =>
        new Worker(new URL('../../utils/workers/tracks/transformTableRowTracksByTimestamp', import.meta.url), {
            type: 'module'
        })
);

const defaultValues = {
    aircraftType: [],
    airline: [],
    callsign: []
};

const frameInterval = 500; // in ms

export default function PlaybackPage() {
    const { config, socket } = useAuthContext();
    const { playbackPage: playbackPageMaxTimerangeInterval = PLAYBACK_PAGE_MAX_TIME_RANGE_DEFAULT_INTERVAL } =
        config?.maxAllowedTimeRangeInterval || {};
    const { clearErrorMsg, errorMsg, form, fetchTracks, resetForm, tracks } = useTracks();
    const [playbackMode, setPlaybackMode] = useState(PlaybackMode.Stop);
    const [playbackIndex, setPlaybackIndex] = useState(0);
    const [connectMsg, setConnectMsg] = useState<string | null>(null);

    const { control, formState, watch } = useForm<FilterForm>({
        defaultValues,
        mode: 'onSubmit'
    });

    const airline = watch('airline');
    const callsign = watch('callsign');
    const aircraftType = watch('aircraftType');

    const clearConnectMsg = useCallback(() => setConnectMsg(null), []);

    async function onSubmit({ start, end }: DateTimeRangeForm) {
        if (start && end) {
            await fetchTracks(start, end);
        }
    }

    // filtered by top-level inputs
    const filteredTracksByTimestamp = useMemo(() => {
        const start = form?.getValues('start');
        const end = form?.getValues('end');

        const filteredTracks = filterTracks(tracks, {
            Airline: airline,
            AircraftType: aircraftType,
            Callsign: callsign
        });

        return transformPlaybackTracks(filteredTracks, start, end, frameInterval);
    }, [aircraftType, airline, callsign, form, tracks]);

    const trackList = useWorkerMemo(transformTableRowTracksByTimestamp, filteredTracksByTimestamp) || [];

    // selected by the drop-down table
    const { selectedTracks, onSelect, onSelectAll, isSelected } = useTableSelect(trackList);

    const selectedTracksByTimestamp = useMemo(() => {
        if (!selectedTracks?.length) {
            return [];
        }

        if (selectedTracks.length === filteredTracksByTimestamp.length) {
            return filteredTracksByTimestamp;
        }

        return filteredTracksByTimestamp.map((tracksByTimestamp) => {
            return {
                timestamp: tracksByTimestamp.timestamp,
                tracks: tracksByTimestamp.tracks.filter((track) =>
                    selectedTracks.some((trackNumber) => trackNumber === track.TrackNumber)
                )
            };
        });
    }, [filteredTracksByTimestamp, selectedTracks]);

    function pause() {
        setPlaybackMode(PlaybackMode.Pause);
    }

    function play() {
        setPlaybackMode(PlaybackMode.Play);
        resetForm?.();
    }

    function stop() {
        setPlaybackMode(PlaybackMode.Stop);
        setPlaybackIndex(0);
    }

    function getTimestamp(timestamp?: number) {
        const datetime = timestamp ? format(new Date(timestamp), 'Ppp') : 'N/A';
        return <>Time being displayed: {datetime}</>;
    }

    // stop at end of playback
    useEffect(() => {
        if (playbackMode === PlaybackMode.Play) {
            const interval = setInterval(() => {
                setPlaybackIndex((index) => {
                    if (index < filteredTracksByTimestamp.length - 1) {
                        return index + 1;
                    } else {
                        setPlaybackMode(PlaybackMode.Pause);
                        clearInterval(interval);
                        return index;
                    }
                });
            }, frameInterval);

            return () => {
                clearInterval(interval);
            };
        }
    }, [playbackMode, filteredTracksByTimestamp.length]);

    useEffect(() => {
        function onConnect() {
            return setConnectMsg('Connection established, ready for playback!');
        }

        function onDisconnect() {
            return setConnectMsg(`Connection terminated.`);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.connect();

        return () => {
            socket.disconnect();
            socket.off('connection', onConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, []);

    return (
        <PageContainer>
            <ControlCard>
                <Grid alignItems="flex-start" container justifyContent="center" spacing={3} rowSpacing={3}>
                    <Grid item xs={12} lg={5}>
                        <DateTimeRangePicker
                            disabled={playbackMode === PlaybackMode.Play}
                            form={form}
                            maxRangeInMinutes={playbackPageMaxTimerangeInterval}
                            onSubmit={onSubmit}
                        />
                    </Grid>

                    <Grid item xs={12} lg={5}>
                        <PlaybackFilters
                            control={control}
                            disabled={playbackMode === PlaybackMode.Play}
                            tracks={tracks}
                        />
                    </Grid>

                    <Grid item xs={12} lg="auto">
                        <PlaybackControls
                            disabled={!tracks?.length || formState.isSubmitting}
                            pause={pause}
                            play={play}
                            playDisabled={playbackIndex >= filteredTracksByTimestamp.length - 1}
                            playbackMode={playbackMode}
                            stop={stop}
                        />
                    </Grid>
                </Grid>
            </ControlCard>

            <Map>
                <PlaybackMarkers tracks={selectedTracksByTimestamp[playbackIndex]?.tracks} />
                <FloatingLeftGroup>
                    {!!trackList?.length && (
                        <Table
                            data={trackList}
                            disabled={playbackMode === PlaybackMode.Play}
                            isSelected={isSelected}
                            numSelected={selectedTracks.length}
                            onSelect={onSelect}
                            onSelectAll={onSelectAll}
                            rowCount={trackList.length}
                            rowId="TrackNumber"
                        />
                    )}
                    {!!filteredTracksByTimestamp?.[playbackIndex]?.timestamp && (
                        <Typography>{getTimestamp(filteredTracksByTimestamp?.[playbackIndex]?.timestamp)}</Typography>
                    )}
                </FloatingLeftGroup>
                <FloatingBottomRightGroup>
                    <Typography>
                        Hold Shift + Alt while dragging to rotate the map
                        <br />
                        Press the compass arrow to reset view to North
                    </Typography>
                </FloatingBottomRightGroup>
                <Toast message={errorMsg} onClose={clearErrorMsg} />
                <Toast message={connectMsg} onClose={clearConnectMsg} severity="success" />
            </Map>
        </PageContainer>
    );
}
